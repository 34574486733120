
import axios from "axios";
import urls from "@/shared/urls";
import {
  handleRequestError,
} from "../shared/utils/response-error-handler";
var qs = require("qs");

var baseUrl = urls.OrdersV2Url;
var endpoint = "/export";
export default {
  
  async get(data) {
      console.log('data:', data);

    try {
      const response = await axios.get(`${baseUrl}${endpoint}`, {
        headers: {
          Authorization: `Bearer ${data.jwtToken}`
        },
        params: {
          o: data.selectedOrders
        },
        paramsSerializer: function (params) {
          return qs.stringify(params, {
            arrayFormat: "repeat"
          });
        }
      });
      
    

      return response;
    }
    catch (error) {
          return {
        data: handleRequestError(error),
        success: false,
      };

    }
  }
};