import urls from "@/shared/urls";


var getUrl = window.location;
var baseUrl = getUrl.protocol + "//" + getUrl.host + "/"; //+ getUrl.pathname.split('/')[1];

export const oidcSettings = {
  authority: urls.IdentityServerBaseUrl,
  clientId: 'ngp',
  //redirectUri: urls.TripFocusUrl + 'oidc-callback',
  redirectUri: baseUrl + 'oidc-callback',
  responseType: 'code',
  scope: 'CustomerRelationshipsApi IdentityServerApi ImagingApi KAGCompaniesApi LoadTendersApi LocationsApi NotificationsApi offline_access openid OrdersApi profile UserAccountManagerApi CommoditiesApi LoggingApi',
  automaticSilentRenew: true,
  //silentRedirectUri: urls.TripFocusUrl + 'oidc-silent-renew',
  silentRedirectUri: baseUrl + 'oidc-silent-renew',
  automaticSilentSignin: false,
  clockSkew: 1200,
  test: baseUrl + 'oidc-callback',
}
