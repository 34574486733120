import UserRepository from "./userRepository"
import UserRelationshipFiltersRepository from "./userRelationshipFiltersRepository"
import CustomerRelationshipFiltersRepository from "./customerRelationshipFiltersRepository"
import RelationshipFiltersPreviewRepository from "./relationshipFiltersPreviewRepository"
import FlattenedFiltersRepository from "./flattenedFiltersRepository"
import BillTosRepository from "./billTosRepository"
import CustomersRepository from "./customerRepository"
import DocTypesRepository from "./docTypesRepository"
import MasterPermissionsRepository from "./masterPermissionsRepository"
import CompaniesRepository from "./companiesRepository"
import OrderExportRepository from "./orderExportRepository"
import UserMessagesRepository from "./userMessagesRepository"
import MessagesRepository from "./messagesRepository"
import OrderRepository from "./orderRepository"
import ProfileRepository from "./profileRepository"
import CommodityClassRepository from "./commodityClassRepository"


const repositories = {
  users: UserRepository,
  userRelationshipFilters: UserRelationshipFiltersRepository,
  customerRelationshipFilters: CustomerRelationshipFiltersRepository,
  billTosRepository: BillTosRepository,
  customersRepository: CustomersRepository,
  docTypesRepository: DocTypesRepository,
  masterPermissionsRepository: MasterPermissionsRepository,
  relationshipFiltersPreviewRepository: RelationshipFiltersPreviewRepository,
  flattenedFiltersRepository: FlattenedFiltersRepository,
  companiesRepository: CompaniesRepository,
  orderExportRepository: OrderExportRepository,
  userMessagesRepository: UserMessagesRepository,
  messagesRepository: MessagesRepository,
  ordersRepository: OrderRepository,
  profileRepository: ProfileRepository,
  commodityClassRepository: CommodityClassRepository
};

export const RepositoryFactory = {
  get: name => repositories[name]
}
