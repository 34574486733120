import axios from "axios";
import urls from "@/shared/urls";
import moment from "moment"; 

//TODO: Why is this just setted to true and exported, what is the reason for this to exists?
const namespaced = true;
//TODO: Could this be refactored to an import statement, if so how can I test that the functionality doesn't break
var qs = require("qs");

const getDefaultFilters = () => {
  return {
    orderReferenceNumber: null,
    deliveredFrom: null, //moment().subtract(2, 'M').format("MM/DD/YYYY"),
    deliveredTo: null, //moment().add(1, 'M').format("MM/DD/YYYY"),
    shippedFrom: null,
    shippedTo: null,
    invoicedFrom: null,
    invoicedTo: null,
    createdFrom: null,
    createdTo: null,
    selectedStatuses: [],
    selectedBillTos: [],
    selectedConsignees: [],
    selectedShippers: [],
    selectedSuppliers: [],
    selectedAccountOfs: [],
    selectedCities: [],
    selectedStates: [],
    selectedTerminals: [],
    selectedCommodityClasses: [],
    datesExpanded: false,
    advancedFieldsExpaned: false,
    basicFieldsExpaned: true,
    selectedAmountUnitOfMeasure: null,
    division: null
  };
};

const state = {
  total: 0,
  currentPage: 1,
  orders: [],
  orderDetails: null,
  selectedOrders: [],
  isFilterApplied: false,
  filterObject: {
    ...getDefaultFilters()
  }
};

const mutations = {
  SET_ORDERS: (state, payload) => {
    state.orders = payload.data;
    state.pageSize = 20;
    state.total = payload ? payload.totalRecords : 0;
  },

  SET_ORDER_DETAILS: (state, payload) => {
    state.orderDetails = payload;
  },

  SET_ORDERS_STATUS_TO_CANCELLED: (state, payload) => {
    state.orders.find(w => w.tmsOrderNumber === payload).orderStatus = "CAN";
  },

  CLEAR_ORDER_FILTERS: state => {
    (state.total = 0),
		(state.currentPage = 1),
		(state.orders = []),
		(state.orderDetails = null),
		(state.selectedOrders = []),
		(state.isFilterApplied = false),
		(state.filterObject = { ...getDefaultFilters() });
  },

  CLEAR_ORDER_SELECTION: state => {
    state.selectedOrders = [];
    state.orders.forEach(w => (w.selected = false));
  },

  SET_ORDER_SELECTION: (state, payload) => {
    state.orders
      .filter(w => payload.orderNumbers.some(n => n === w.tmsOrderNumber))
      .forEach(w => (w.selected = payload.val));
    state.selectedOrders = payload.val
      ? state.selectedOrders.concat(payload.orderNumbers)
      : state.selectedOrders.filter(
          w => !payload.orderNumbers.some(x => x === w)
        );
  },

  SET_SEARCH_TERM: (state, payload) => {
    state.searchTerm = payload;
  },
	
  SET_PAGINATION_PAGE: (state, payload) => {
    state.currentPage = payload;
  },
	
	//TODO: Maybe we could delete this. It is not doing anything.
  RESET_RELOAD: state => {
     let oldSearch = state.searchTerm;
     console.log('oldSearch',oldSearch);
    // if (state.searchTerm || state.selectedStatusFilters.length || state.filterDateFrom || state.filterDateTo) {
    //   Object.assign(state, getDefaultFilters());
    //   if (state.searchTerm === oldSearch) {
    //     state.searchTerm = null; // trick to always trigger watch
    //   }
    // }
  },

  CLEAR_ORDERS: state => {
    state.orders = [];
  },

  SET_FILTER_OBJECT: (state, payload) => {
    state.filterObject = payload;
  },

  SET_IS_FILTER_APPLIED: (state, payload) => {
    state.isFilterApplied = payload;
  }
};

const actions = {
  async getAllOrders(context, requestData) {
   
      
    var reqParams = {
      skip: (requestData.currentPage - 1) * (requestData.ordersReturned),
      take: requestData.ordersReturned
    }
    
    if (requestData.filters.orderReferenceNumber) {
      var separators = [" ", ",", ";", "&", "%", "_"];

      reqParams[
        "referenceNumber"
      ] = requestData.filters.orderReferenceNumber.split(
        new RegExp(separators.join("|"), "g")
      );
    }

    if (
      requestData.filters.selectedBillTos &&
      requestData.filters.selectedBillTos.length > 0
    ) {
      reqParams["billTo"] = requestData.filters.selectedBillTos.map(a => a.id);
    }

    if (
      requestData.filters.selectedAccountOfs &&
      requestData.filters.selectedAccountOfs.length > 0
    ) {
      reqParams["accountOf"] = requestData.filters.selectedAccountOfs.map(
        a => a.id
      );
    }

    if (
      requestData.filters.selectedShippers &&
      requestData.filters.selectedShippers.length > 0
    ) {
      reqParams["shipper"] = requestData.filters.selectedShippers.map(
        a => a.id
      );
    }

    if (
      requestData.filters.selectedSuppliers &&
      requestData.filters.selectedSuppliers.length > 0
    ) {
      reqParams["supplier"] = requestData.filters.selectedSuppliers.map(
        a => a.id
      );
    }

    if (
      requestData.filters.selectedConsignees &&
      requestData.filters.selectedConsignees.length > 0
    ) {
      reqParams["consignee"] = requestData.filters.selectedConsignees.map(
        a => a.id
      );
    }

    if (
      requestData.filters.selectedStatuses &&
      requestData.filters.selectedStatuses.length > 0
    ) {
      reqParams["status"] = requestData.filters.selectedStatuses.map(
        a => a.value
      );
    }

    if (
      requestData.filters.selectedCities &&
      requestData.filters.selectedCities.length > 0
    ) {
      reqParams["deliverycity"] = requestData.filters.selectedCities;
    }

    if (
      requestData.filters.selectedStates &&
      requestData.filters.selectedStates.length > 0
    ) {
      reqParams["deliverystate"] = requestData.filters.selectedStates.map(
        a => a.abbreviation
      );
    }

    if (
      requestData.filters.selectedTerminals &&
      requestData.filters.selectedTerminals.length > 0
    ) {
      reqParams["terminal"] = requestData.filters.selectedTerminals.map(
        a => a.abbreviation
      );
    }

    if (
      requestData.filters.selectedCarriers &&
      requestData.filters.selectedCarriers.length > 0
    ) {
      reqParams["carrier"] = requestData.filters.selectedCarriers.map(
        a => a.id
      );
    }

    if (
      requestData.filters.selectedCommodityClasses &&
      requestData.filters.selectedCommodityClasses.length > 0
    ) {
      reqParams[
        "commodityClass"
      ] = requestData.filters.selectedCommodityClasses.map(a => a.id);
    }

    if (requestData.filters.createdTo) {
      var createdEndDate = moment(requestData.filters.createdTo, "MM-DD-YYYY").add(1, 'day').format(
        "YYYY-MM-DD"
      );
      reqParams["CreatedDateEnd"] = createdEndDate;
    }

    if (requestData.filters.createdFrom) {
      var createdFromDate = moment(requestData.filters.createdFrom, "MM-DD-YYYY").format(
        "YYYY-MM-DD"
      );
      reqParams["CreatedDateStart"] = createdFromDate;
    }

    if (requestData.filters.deliveredTo) {
      var endDate = moment(requestData.filters.deliveredTo, "MM-DD-YYYY").add(1, 'day').format(
        "YYYY-MM-DD"
      );
      reqParams["deliveredDateEnd"] = endDate;
    }

    if (requestData.filters.deliveredFrom) {
      var fromDate = moment(requestData.filters.deliveredFrom, "MM-DD-YYYY").format(
        "YYYY-MM-DD"
      );
      reqParams["deliveredDateStart"] = fromDate;
    }

    if (requestData.filters.shippedTo) {
      var shippedEndDate = moment(requestData.filters.shippedTo, "MM-DD-YYYY").add(1, 'day').format(
        "YYYY-MM-DD"
      );
      reqParams["ShippedDateEnd"] = shippedEndDate;
    }

    if (requestData.filters.shippedFrom) {
      var shippedFromDate = moment(requestData.filters.shippedFrom, "MM-DD-YYYY").format(
        "YYYY-MM-DD"
      );
      reqParams["ShippedDateStart"] = shippedFromDate;
    }

    if (requestData.filters.invoicedTo) {
      var invoiceEndDate = moment(requestData.filters.invoicedTo, "MM-DD-YYYY").add(1, 'day').format(
        "YYYY-MM-DD"
      );
      reqParams["InvoicedDateEnd"] = invoiceEndDate;
    }

    if (requestData.filters.invoicedFrom) {
      var invoiceFromDate = moment(requestData.filters.invoicedFrom, "MM-DD-YYYY").format(
        "YYYY-MM-DD"
      );
      reqParams["InvoicedDateStart"] = invoiceFromDate;
    }

    reqParams["sort"] = requestData.sort;

    var orders = await axios.get(`${urls.OrdersV2Url}`, {
      headers: {
        Prefer: requestData.preferHeader,
        Pragma: "no-cache"
      },
      params: reqParams,
      paramsSerializer: function(params) {
        return qs.stringify(params, {
          arrayFormat: "repeat"
        });
      }
    });

    context.commit("SET_ORDERS", orders.data);
  },

	//TODO: This might not need to be here, it's just making a call not using the data in any part of the store.
	// eslint-disable-next-line
  async getOrderDetails(context, requestData) {
    var url = `${urls.OrdersV2Url}/${requestData.division}/${requestData.orderNumber}`;
    var orderDetails = await axios.get(url, {
      headers: {
        Prefer: requestData.preferHeader,
        Pragma: "no-cache"
      }
    });
    return orderDetails.data;
  },

	//TODO: This might not need to be here, it's just making a call not using the data in any part of the store.
	// eslint-disable-next-line
  async terminal(context, requestData) {
    var url = `${urls.OrdersV2Url}/${requestData.division}/terminal/${requestData.orderNumber}`;
    var orderDetailsTerm = await axios.get(url, {
      headers: {
        Prefer: requestData.preferHeader,
        Pragma: "no-cache"
      }
    });
    return orderDetailsTerm.data;
  },

  async cancelOrder(context, requestData) {
    try {
      var order = requestData.order;
      await axios.put(`${urls.LoadTendersUrl}/${requestData.id}`, order, {
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      });
      context.commit("SET_ORDERS_STATUS_TO_CANCELLED", requestData.id);
      return {
        success: true
      };
    } catch (error) {
      return {
        success: false,
        message: error
      };
    }
  },

  setSearchTerm(context, term) {
    context.commit("SET_SEARCH_TERM", term);
  },

  clearOrderFilters(context) {
    context.commit("CLEAR_ORDER_FILTERS");
  },

  setPagination(context, page) {
    context.commit("SET_PAGINATION_PAGE", page);
  },

	//TODO: Maybe we could delete this. It is not doing anything in the commit
  resetReload(context) {
    context.commit("RESET_RELOAD");
  },

  setOrderSelection(context, data) {
    context.commit("SET_ORDER_SELECTION", data);
  },

  clearOrderSelection(context) {
    context.commit("CLEAR_ORDER_SELECTION");
  },

  clearOrders(context) {
    context.commit("CLEAR_ORDERS");
  },

  setFilterObject(context, payload) {
    context.commit("SET_FILTER_OBJECT", payload);
  },

  setIsFilterApplied(context, payload) {
    context.commit("SET_IS_FILTER_APPLIED", payload);
  },

  setOrderReferenceNumber(context, payload) {
    context.commit("SET_ORDER_REFERENCE_NUMBER", payload);
  }
}

export default {
  namespaced,
  state,
  mutations,
  actions
}
